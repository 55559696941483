<template>
    <div class="container">
        <Header page="home" />
        <div class="middle">
            <h1>404 Not Found</h1> 
        </div> 
    </div>
    
</template>

<script>
import Header from '@/components/Layout/Header.vue' 
export default {
    components:{
        Header
    }
}
</script>


<style scoped>
 
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
